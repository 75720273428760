import React from 'react';
import './contact-us.css'

const ContactUs = () => {
    return (
        <section className='contact-us'>
            <h2 className='generic-header'>Contact Us</h2>
            <div>
                <p>Get in touch with our
                    <a className='general-link-text' href="mailto:alawrencescott@gmail.com?subject=[Business Inquiry]&body=Hello,%20I%20have%20a%20question%20about%20your%20services."> solutions team at alawrencescott@gmail.com</a>
                </p>
                Get the data you need to optimize your strategy with Extract Experts. Our advanced AI and interactions handling allows for effortless navigation challenges like captchas, logins, and complex websites, making data extraction from modern sites easy. Efficiently deliver your data exactly where and how you need it. Book a free consultation to begin streamlining your data collection process today.
            </div>
        </section>
    )
}


export default ContactUs;
